import React from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from 'components/ReflexBox';
import { useStaticQuery, graphql } from 'gatsby';

import { H4, TextLight } from 'components/Typo';
import { getThemeColor, getThemeSpacePx } from 'utils/css-utils';
import Icon from 'components/Icon';

const AwardWrapper = styled(Flex)`
  border-top: 1px solid ${getThemeColor('silverDarken60')};
  padding: ${getThemeSpacePx(3)} 0;
  margin-bottom: ${getThemeSpacePx(2)};
  flex-wrap: wrap;

  ${H4} {
    margin: 0;
  }
`;

const Award = ({ name, project, category, client }) => {
  return (
    <AwardWrapper>
      <Box width={[1, 3 / 7]}>
        <H4 as="div">{name}</H4>
        <Flex mt={2} alignItems={['center', 'center']}>
          <Icon
            size={21}
            strokeColor="white"
            name="trophy"
            colorizeStroke
            mr={2}
          />
          <TextLight>{category}</TextLight>
        </Flex>
      </Box>
      <Box width={[1, 2 / 7]} pl={[0, 2]} my={[1, 0]}>
        <H4 style={{ fontWeight: 400 }} as="div">
          {project}
        </H4>
      </Box>
      <Box width={[1, 2 / 7]}>
        <TextLight textAlign={['start', 'end']}>{client}</TextLight>
      </Box>
    </AwardWrapper>
  );
};

export default (props) => {
  const rawData = useStaticQuery(graphql`
    query AwardListQuery {
      allAwardsJson(filter: { isVisible: { eq: true } }) {
        edges {
          node {
            id
            name
            category
            isVisible
            website
            project
            client
          }
        }
      }
    }
  `);

  const awards = rawData.allAwardsJson.edges.map(({ node }) => node);

  return (
    <Box {...props}>
      {awards.map((award) => (
        <Award key={award.id} {...award} />
      ))}
    </Box>
  );
};
