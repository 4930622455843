import React from 'react';
import { Flex, Box } from 'components/ReflexBox';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

import Page from 'components/Page';
import ContentSection from 'components/ContentSection';
import SectionIntro from 'components/SectionIntro';
import { H3, TextLight } from 'components/Typo';
import AwardList from 'components/AwardList';

const metaTags = {
  title: 'webkid - about',
  author: 'Webkid',
  twitterUsername: '@wbkd',
  description:
    'Get to know webkid and its founders Moritz Klack & Christopher Möller',
  siteUrl: 'https://webkid.io/about/',
  robots: 'index,follow',
};

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      file(
        sourceInstanceName: { eq: "assets" }
        relativePath: { eq: "images/portraits/webkid-portrait-wide.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 1200)
        }
      }
    }
  `);

  return (
    <Page metaTags={metaTags} showBanner>
      <ContentSection centered>
        <SectionIntro
          title="About"
          text="Since its foundation in 2014, webkid has built and maintained a good reputation in the field of interactive news. We have worked with many renowned media outlets such as The Financial Times, Zeit Online and Berliner Morgenpost."
        />
        <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} />
        <Box css={{ overflow: 'hidden' }} my={4}>
          <H3>Hi, we are webkid!</H3>
          <Flex mx={-4} flexWrap="wrap">
            <Box px={4} width={[1, 1, 1 / 2]}>
              <TextLight mb={3}>
                Moritz Klack and Christopher Möller met when they studied Media
                and Computer Science in Berlin. As students they both worked as
                developers for the German daily newspaper <i>Welt</i>. They also
                collaborated on small side projects. Through these activities
                they realized that their common interest in journalism could be
                combined with their work as web developers. Around the same time
                they started developing interactive visualizations for the
                Berliner Morgenpost, a pioneer of data journalism in Germany.
                After graduation they founded webkid as an agency for data
                visualization in 2014.
              </TextLight>
            </Box>
            <Box px={4} width={[1, 1, 1 / 2]}>
              <TextLight mb={3}>
                The first webkid projects were almost exclusively developed for
                their former employers <i>Welt</i> and{' '}
                <i>Berliner Morgenpost</i>. Through their experience of working
                in the newsroom they were able to adapt their working methods to
                the requirements of journalists. Other news organizations
                quickly became aware of webkid and thus the range of projects
                increased. Some of the projects they have been involved in have
                won prestigious awards, which has drawn the attention of clients
                from other areas as well. This has given webkid the opportunity
                to constantly expand its portfolio and to work on exciting new
                projects.
              </TextLight>
            </Box>
          </Flex>
        </Box>
      </ContentSection>
      <ContentSection centered bg="sectionBackground">
        <SectionIntro
          title="Selected Awards"
          text="Some of our work has been nominated for prestigious awards. Here is a selection of awards for our project collaborations."
        />
        <AwardList />
      </ContentSection>
    </Page>
  );
};

export default About;
